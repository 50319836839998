export const urls = {
    "Homepage": {
        en: '/',
        de: '/de/',
        fr: '/fr/',
        nl: '/nl/',
        es: '/es/',
        nb: '/nb/',
        fi: '/fi/',
        pt: '/pt/',
    },
    "Deco2fire": {
        en: '/deco2fire/',
        de: '/de/deco2fire/',
        fr: '/fr/deco2fire/',
        nl: '/nl/deco2fire/',
        es: '/es/deco2fire/',
        nb: '/nb/deco2fire/',
        fi: '/fi/deco2fire/',
        pt: '/pt/deco2fire/',
    },
    "Decontomination": {
        en: '/decon-solution/',
        de: '/de/dekon-losung/',
        fr: '/fr/decon-solution/',
        nl: '/nl/decon-solution/',
        es: '/es/decon-solucion/',
        nb: '/nb/decon-solution/',
        fi: '/fi/decon-solution/',
        pt: '/pt/decon-solution/',
    },
    "About": {
        en: '/about-us/',
        de: '/de/uber-uns/',
        fr: '/fr/about-us/',
        nl: '/nl/about-us/',
        es: '/es/sobre-nosotros/',
        nb: '/nb/about-us/',
        fi: '/fi/about-us/',
        pt: '/pt/about-us/',
    },
    "Case Studies": {
        en: '/case-studies/',
        de: '/de/fallstudien/',
        fr: '/fr/case-studies/',
        nl: '/nl/case-studies/',
        es: '/es/estudios-caso/',
        nb: '/nb/case-studies/',
        fi: '/fi/case-studies/',
        pt: '/pt/case-studies/',
    },
    "Lco2": {
        en: '/pressurized-co2/',
        de: '/de/druck-co2/',
        fr: '/fr/pressurized-co2/',
        nl: '/nl/pressurized-co2/',
        es: '/es/co2-presurizado/',
        nb: '/nb/pressurized-co2/',
        fi: '/fi/pressurized-co2/',
        pt: '/pt/pressurized-co2/',
    },
    "Faq": {
        en: '/faq/',
        de: '/de/faq/',
        fr: '/fr/faq/',
        nl: '/nl/faq/',
        es: '/es/faq/',
        nb: '/nb/faq/',
        fi: '/fi/faq/',
        pt: '/pt/faq/',
    },
    "Contact": {
        en: '/contact/',
        de: '/de/kontakt/',
        fr: '/fr/contact/',
        nl: '/nl/contact/',
        es: '/es/contacto/',
        nb: '/nb/contact/',
        fi: '/fi/contact/',
        pt: '/pt/contact/',
    },
    "Freebies": {
        en: '/download/',
        de: '/de/download/',
        fr: '/fr/download/',
        nl: '/nl/download/',
        es: '/es/descargar/',
        nb: '/nb/download/',
        fi: '/fi/download/',
        pt: '/pt/download/',
    },

    "Privacy-police": {
        en: '/cookie-policy/',
        de: '/de/cookie-richtlinie/',
        fr: '/fr/cookie-policy/',
        nl: '/nl/cookie-policy/',
        es: '/es/cookie-policy/',
        nb: '/nb/cookie-policy/',
        fi: '/fi/cookie-policy/',
        pt: '/pt/cookie-policy/',
    },
    "Terms And Conditions": {
        en: '/privacy-statement/',
        de: '/de/datenschutzerklaerung/',
        fr: '/fr/privacy-statement/',
        nl: '/nl/privacy-statement/',
        es: '/es/privacy-statement/',
        nb: '/nb/privacy-statement/',
        fi: '/fi/privacy-statement/',
        pt: '/pt/privacy-statement/',
    },

    "Blog-archive": {
        en: '/blog/',
        de: '/de/blog/',
        fr: '/fr/blog/',
        nl: '/nl/blog/',
        es: '/es/blog/',
        nb: '/nb/blog/',
        fi: '/fi/blog/',
        pt: '/pt/blog/',
    },

    "News-archive": {
        en: '/news/',
        de: '/de/nachrichten/',
        fr: '/fr/news/',
        nl: '/nl/news/',
        es: '/es/notitias/',
        nb: '/nb/news/',
        fi: '/fi/news/',
        pt: '/pt/news/',
    },

    'Applications': {
        en: '/applications/',
        de: '/de/applications/',
        fr: '/fr/applications/',
        nl: '/nl/applications/',
        es: '/es/applications/',
        nb: '/nb/applications/',
        fi: '/fi/applications/',
        pt: '/pt/applications/',
    },

    'Guarantee': {
        en: '/guarantee/',
        de: '/de/guarantee/',
        fr: '/fr/guarantee/',
        nl: '/nl/guarantee/',
        es: '/es/guarantee/',
        nb: '/nb/guarantee/',
        fi: '/fi/guarantee/',
        pt: '/pt/guarantee/',
    },

    'Social-responsibility': {
        en: '/social-responsibility/',
        de: '/de/social-responsibility/',
        fr: '/fr/social-responsibility/',
        nl: '/nl/social-responsibility/',
        es: '/es/social-responsibility/',
        nb: '/nb/social-responsibility/',
        fi: '/fi/social-responsibility/',
        pt: '/pt/social-responsibility/',
    },
}
